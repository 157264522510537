import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Spacer from '../components/Spacer'
import '../styles/contact.css'


const NotFoundPage = () => {
  return (
    <Layout pageTitle="Contact Page">
      <div className='mainpage contactpage'>

        <div className='landinggrid left contactgrid'>
          <div className='landingwhitespace'>

          </div>
          <div className="landingwords text valuewords">
            <p>Error 404: Page not found</p>
          </div>

          <StaticImage
            alt="404 Page"
            src="../images/contact-transformed.png"
            className="image landingimage" />

        </div>

        <Spacer classes="h10 mobilehide" />


        <article className='contactdetails text'>
          <p>Sorry 😔, we couldn’t find what you were looking for.</p>
      
          <div className="email"><Link to="/">Head back home?</Link></div>

        </article>
 
        <Spacer classes="h50" />

      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Not Found" />

export default NotFoundPage
